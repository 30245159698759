*
{
    font-size: 0.9rem;
}

pre
{
    font-family: 'Muli', sans-serif
}

body
{
    margin: 1rem 0 0 0;
    font-family: 'Muli', sans-serif
}

select {
    /* -webkit-appearance: auto !important; */
    height: 2.4rem;
}

select:is(:not(:disabled)) {
    background-color: white;
}

select:is(:disabled) {
    background-color: #e9ecef;
}

.encart {
    padding: 0.3rem;
}

.encart .encart-title{
    color: #17ace4;
    display: flex;
    justify-content: space-between;
}

.encart hr {
    height: 0px;
    border: 1px solid #17ace4;
}


#regForm {
    background-color: #ffffff;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    margin: 0 1.5rem;
}

#regForm p, 
#regForm u,
#regForm li
{
    font-size: 18px;
    margin: 10px 0px;
}

#regForm b
{
    font-size: 16px;
}


input {
    font-size: 17px;
    font-family: Raleway;
    border: 1px solid #aaaaaa;
    font-family:sans-serif;
}

input[type="checkbox"]
{
    width: 1.53rem;
    height: 1.53rem;
}

input[type="number"], input[type="text"],  input[type="email"], textarea, select{
    border: 1px solid #17ACE4;
    width: 90%;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    /* -moz-appearance: textfield; */
}

input[type="number"] ::-webkit-outer-spin-button,
input[type="number"] ::-webkit-inner-spin-button,
input[type="email"] ::-webkit-outer-spin-button,
input[type="email"] ::-webkit-inner-spin-button,
input[type="text"] ::-webkit-outer-spin-button,
input[type="text"] ::-webkit-inner-spin-button 
{
    -webkit-appearance: none;
    margin: 0;
}

/* Mark input boxes that gets an error on validation: */
input.invalid {
    background-color: #ffdddd !important;
}


input.invalid+em {
    visibility: visible;
    color: red
}

/* Make circles that indicate the steps of the form: */
.step {
    height: 44px;
    width: 44px;
    margin: 0 30px;
    background-color: #bbbbbb;
    border: none;
    border-radius: 50%;
    opacity: 0.5;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 20px
}

.step:not(:last-child):after {
    content: "━━━";
    position: absolute;
    right: 0;
    right: -135%;
    font-weight: bold;
    font-size: 20px;
    color: #bbbbbb;

}

@-moz-document url-prefix() {
    .step:not(:last-child):after {
        content: "━━━━━";
        right: -126%;
    }
}

/* Mark the active step: */
.step.active {
    opacity: 1;
    background-color: #17ace4;
    color: white
}

/* Mark the steps that are finished and valid: */
.step.finish {

    background-color: #17ace4;
    color: white;
}

.step.active:after {
    color: #bbbbbb;
    opacity: .6
}

.step.finish:after {
    color: #17ace4;
}

.submitBtn:is(.disabled)
{
    background-color: #ccc;
    cursor: not-allowed;
}

#prevBtn, #submitBtn, .submitBtn {
    border: none;
    font-family: sans-serif;
    color: white;
    background-color: #0C2A6F;
    opacity: 0.7;
    padding: 10px 20px;
    font-size: 18px;
    margin: 10px;
    cursor: pointer;
    border-radius: 6px;
}

#regForm button:hover {
    opacity: 1;
}

#regForm #submitBtn:hover, .submitBtn:hover {
    opacity: 1;
}

#regForm button #nextBtn,
#regForm #submitBtn, .submitBtn {
    opacity: 1
}

input[type="submit"]:disabled {
    background: #ccc !important;
}

.tab-title,
.tab-second-title {
    text-align: center;
    margin-bottom: 16px
}

.tab ul li {
    margin: 10px 0px;
}

.tab ul li::before {
    content: '8';
    margin-left: -20px;
    margin-right: 10px;
}

.tab ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.tab ul li {
    padding-left: 20px;

}

.tab ul li:before {
    content: "❌";
    padding-right: 5px;
}


.break-text, .break-long-text
{
    font-size: 0.9rem!important;
    line-height: 1.2rem!important;
}

.break-text
{
    word-wrap: break-word;
    word-break: break-all;
}

.break-long-text
{
    max-width: 85vw;
    word-wrap: break-word;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;  
}

/* The container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #eee;
    border: 2px solid #2196f3;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
    background-color: #17ace4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

input[type=file] {
    border-radius: .2em;
    margin: 10px 0px;
}

input[type=file].valid:after {
    content: "✔️";
    float: right
}

input[type=file]::file-selector-button {
    border: none;
    padding: .2em .4em;
    border-radius: .2em;
    background-color: #17ACE4;
    transition: 1s;
    cursor: pointer;
    color: white;
}

input[type=file]::file-selector-button:hover {
    opacity: .9
}

a.fileBtn {
    cursor: pointer;
    margin-left: auto;
}

.obligatoire{
    color: red;
}

select.cond_obligatoire.invalid{
    background:#ffdddd;
}

h1{
    color: orangered;
}

input .input_off, .input_off { 
    background-color: #ffffff !important; border: 1px solid #17ACE4 !important; border-radius:10px !important;
} 

.fa-icon-file
{
    color: #17ace4;
    font-size: 2rem;
    margin-right: 0.5rem;
}

.fa-icon-close
{
    color: red;
    font-size: 1.7rem;
    cursor: pointer;
    display: flex;
    align-self: center;
    margin: 0 0.5rem;
}

em p {
    color: red;
    font-weight: bold;
}

.bold{
    font-weight: bold;
}

.margin8px
{
    margin: 8px 0;
}

.charNum
/* #countExplic */
{
    font-size: 1rem;
    font-weight:bold;  
    color:#17ace4;  
    opacity:0.8
}

textarea
{
    height:170px;
    font-size:16px;
    max-width:1200px
}

.form-title
{
    text-align:center;
    margin-top:-10px;
    margin-bottom:14px
}

#step_bar
{
    text-align:center; 
    margin:20px auto;
}

.orange-label
{
    color:#E7590F;
}

.file
{
    height: 100%;
}

.content
{
    margin:2rem 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content-left
{
    padding: 0 2rem 0 3rem;
}

.content-right 
{
    background-color: #f4f4f4;
    height: fit-content;
    padding: 0.5rem;
    margin: 0 3rem;
}

#info_droite p 
{
    font-size: 16px;
    line-height: initial;
}


.nav-buttons
{
    display: flex;
    justify-content: center;
}

.ville, .ville_correspondance
{
    background-color:rgb(233, 233, 237);
}

.cursor
{
    cursor: pointer;
}

.text-bold
{
    font-weight: 900;
}

.blue
{
    color:#17ace4
}

.red
{
    color: red!important;
}

.bg-red
{
    background:#ffdddd
}

.orange
{
    color: orange;
}

.bg-grey
{
    background: #e9ecef;
}

.tab8
{
    display: inline-block;
    width: 800px;
    margin-left: 26.5%;
    margin-top: 4rem;
}

.not-allowed
{
    cursor: not-allowed;
}

@media only screen and (max-width:1140px) 
{
    .content
    {
        margin:0 2rem;
    }
}

@media only screen and (max-width:850px) 
{
    #step_bar{ width: 70%}

    .content
    {
        flex-direction: column;
    }

    .content-left
    {
        order: 2;
        width: 100%;
    }

    .content-right 
    {
        order: 1;
        width: 90%;
        align-self: center;
        margin: 1.5rem 0;
    }
}


@media only screen and (max-width:594px) 
{
    *{ font-size: small}
    #step_bar
    { 
        width: 100%; 
        margin: 0 0.5rem 0.5rem 0;
    }
}

@media only screen and (max-width:530px) 
{
    .content
    {
        margin:0;
    }

    .content-right 
    {
        margin: 1rem auto;
        width: 85%;
    }

    .nav-buttons
    {
        flex-direction: column;
    }
}

